import "./shared.css"
import "./App.js"
import Container from 'react-bootstrap/Container';

export function Header(props) {
  return (
    <Container fluid className="cover-sub sub-header-box">
      {props.text}
    </Container>
  )
}

export function FullHeader(props){
  return (
    <div className="long-header-box">
      <Header text={props.text} />
    </div>
  )
}