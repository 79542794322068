import React from 'react';
import './artists.css';
import './App.css';
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import artists from './data/artists';
import { Slide } from "react-slideshow-image";
import { FullHeader } from './shared.js'
import 'react-slideshow-image/dist/styles.css';

function Cover(props) {
  return (
    <Container fluid className='cover-box'>
      <Container fluid className='sub-cover-box-image cover-box-margin'>
        <Container fluid className='sub-cover-padbox' />
        <Row>
          <Col xs={{span: 12, order: 2}} md={{span: 12, order: 1}} className='sub-cover-box-text1'>
            {props.sub}
          </Col>
          <Col xs={{span: 12, order: 1}} md={{span: 12, order: 2}} className='sub-cover-box-text2'>
            {props.main}
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

function FadingWord(props) {
  return (
    <Container fluid className='fading-text da-lb'>
      {props.text}
    </Container>
  )
}

function FadingWordWrapper(props) {
  return (
    <Container fluid className='fading-text-wrapper'>
      <FadingWord text={props.text} />
    </Container>
  )
}

function Artist(props) {
  return (
    <>
      <Row>
        <img alt="" src={require('./img/illust_cut/'+props.index+'.jpg').default} className='artist-pic-box'/>
      </Row>
      <Row className='artist-text-box'>
        <Row className='artist-text-name'>
          {props.a_name}
        </Row>
        <Row className='artist-text-circle'>
          {props.c_name}
        </Row>
        <Row>
          <a href={props.l1_n} className='artist-text-link' target="_blank" rel="noreferrer">
            {props.l1_t}
          </a>
        </Row>
        <Row>
          <a href={props.l2_n} className='artist-text-link' target="_blank" rel="noreferrer">
            {props.l2_t}
          </a>
        </Row>
      </Row>
    </>
  )
}

class ArtistList extends React.Component {

  createArtist(index, a_name, c_name, l1_t, l1_n, l2_t, l2_n) {
    return (
      <Col xs={12} sm={6} md={4} className='artist-box' key={index}>
        <Artist index={index} a_name={a_name} c_name={c_name} l1_t={l1_t} l1_n={l1_n} l2_t={l2_t} l2_n={l2_n} />
      </Col>
    )
  }
  
  createArtistList() {
    var artists_list = []
    for (var i=0; i<artists.length; i++){
      artists_list.push(this.createArtist(artists[i][0], artists[i][1], artists[i][2], artists[i][3], artists[i][4], artists[i][5], artists[i][6]))
    }
    return (
      <>
        {artists_list}
      </>
    )
  }

  render() { 
    return (
      <>
        <Container fluid style={{height: '20px'}} />
        <Row className='artist-list'>
          {this.createArtistList()}
        </Row>
      </>
    )
  }
  
}

function OutlineDetailItem(props) {
  return (
    <Container className='outline-item'>
      <Row>
        <Col xs={4} md={3} className='event-item-box'>
          <Container className='event-item-name-pad' />
          {props.name}
        </Col>
        <Col xs={8} md={9} className='da-medium'>
          {props.text}
        </Col>
      </Row>
    </Container>
  )
}

function Outline() {
  return (
    <Container fluid className='intro-inner-box outline-box'>
      <Container fluid className='intro-content-box'>
        <Row>
          <Col xs={4} sm={4}>
            <Slide easing='ease' cssClass='intro-slide-box' arrows={false}>
              <div className="outline-image-box intro-image-front"></div>
              <div className="outline-image-box intro-image-back"></div>
            </Slide>
          </Col>
          <Col xs={8} sm={8}>
            <Container className='outline-toppad' />
            <Container className='outline-text-box outline-text'>
              <Container className='outline-text-padbox' />
              <OutlineDetailItem name="裝訂" text="A4全彩膠裝" />
              <OutlineDetailItem name="頁數" text="68P" />
              <OutlineDetailItem name="價格" text="HKD150 (會場限定價)" />
            </Container>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

function EventDetailItem(props) {
  return (
    <Container className='event-item'>
      <Row>
        <Col xs={2} className='event-item-box da-medium'>
          <Container className='event-item-name-pad' />
          {props.name}
        </Col>
        <Col xs={10}>
          {props.text}
        </Col>
      </Row>
    </Container>
  )
}

function EventSingleItem(props) {
  return (
    <Container className='event-item'>
      <Row>
        <Col xs={12} className='da-medium' dangerouslySetInnerHTML={props.text}/>
      </Row>
    </Container>
  )
}

function RG26() {
  return (
    <Stack className="event-box">
      <div className="event-header">
        Rainbow Gala 26
      </div>
      <EventDetailItem name='日期' text='2021年12月26日(星期日)'/>
      <EventDetailItem name='地點' text='九龍灣國際展貿中心3樓及6樓 展覽廳2&amp;3'/>
      <EventDetailItem name='時間' text='12:00PM至7:30PM (VIP門票11:00AM開始分4組優先進場)'/>
      <EventDetailItem name='門票' text='標準門票$50 | VIP優先門票$100'/>
      <EventDetailItem name='攤號' text='6樓 WHITE 11B | 香港同人繪師選集 Doujin Anthology HK'/>
      <EventSingleItem text={{__html:'詳情請參閱<a href="https://www.rainbow-gala.com/26/main.html" class="da-link" target="_blank" rel="noreferrer">官方網頁</a>'}}/>
    </Stack>
  )
}

export function Artists() {
  return (
    <>
      <Cover main='繪師名錄' sub='A r t i s t s'/>
      <FullHeader text='A r t i s t s&nbsp;&nbsp;L i s t'/>
      <ArtistList />
    </>
  )
}

export function Purchase() {
  return (
    <>
      <Cover main='入手情報' sub='P u r c h a s e'/>
      <FullHeader text='P u b l i s h i n g&nbsp;&nbsp;O u t l i n e'/>
      <Outline />
      <FullHeader text='E v e n t s'/>
      <RG26 />
      <FadingWordWrapper text="More Coming Soon..." />
      <FullHeader text='B r i c k - a n d - M o r t a r'/>
      <FadingWordWrapper text="Coming Soon..." />
    </>
  )
}

export function Events() {
  return (
    <>
      <Cover main='活動情報' sub='E v e n t s'/>
      <FullHeader text='E v e n t s'/>
      <RG26 />
      <FadingWordWrapper text="More Coming Soon..." />
    </>
  )
}