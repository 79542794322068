import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Nav from 'react-bootstrap/Nav';
import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import { Artists, Purchase, Events } from './artists.js';
import { Header } from './shared.js'
import { Timeline } from 'react-twitter-widgets';
import { Scrollbars } from 'react-custom-scrollbars-2';

function TopbarItem(props) {
  return (
    <>
      {/* <Container className="topbar-main">
        {props.main}
      </Container>
      <Container className="topbar-sub">
        {props.sub}
      </Container> */}
      <Container fluid className="topbar">
        <Row>
          <Col xs={6} sm={12} className="topbar-main">{props.main}</Col>
          <Col xs={6} sm={12} className="topbar-sub">{props.sub}</Col>
        </Row>
      </Container>
    </>
  )
}

class Topbar extends React.Component {

  createTopbarItem(main, sub, link) {
    return (
      <Nav.Link href={link} padding='0px'>
        <TopbarItem main={main} sub={sub}/>
      </Nav.Link>
    )
  }
  
  render(){

    return (
      <Navbar collapseOnSelect fixed='top' expand='sm' className="color-nav">
        <Container fluid className='topbar-box'>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            {/* <Nav>
              <Nav.Link href='/'>Home</Nav.Link>
              <Nav.Link href='/artists'>Artists</Nav.Link>
            </Nav> */}
            <Col xs={12} sm={3}>
              {this.createTopbarItem("首頁", "T o p", "/")}
            </Col>
            <Col xs={12} sm={3}>
              {this.createTopbarItem("繪師名錄", "A r t i s t s", "/artists")}
            </Col>
            <Col xs={12} sm={3}>
              {this.createTopbarItem("入手情報", "P u r c h a s e", "/purchase")}
            </Col>
            <Col xs={12} sm={3}>
              {this.createTopbarItem("活動情報", "E v e n t s", "/events")}
            </Col>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );

  }
  
}

function NavbarPad() {
  return (
    <Container fluid className="pad-box"></Container>
  )
}

function BottomPad() {
  return (
    <Container fluid className="bottom-pad-box"></Container>
  )
}

function Cover() {
  return (
    <Container fluid className='cover-box'>
      <Col className='cover-box-image cover-box-margin'></Col>
    </Container>
  )
}

function Intro() {
  return (
    <Container fluid className='intro-box'>
      <Container fluid className='intro-inner-box'>
        <Container fluid className='intro-content-box'>
          <Row>
            <Col xs={5} sm={4}>
              <Slide easing='ease' cssClass='intro-slide-box' arrows={false}>
                <div className="intro-image-box intro-image-front"></div>
                <div className="intro-image-box intro-image-back"></div>
              </Slide>
            </Col>
            <Col xs={7} sm={8}>
              <Container className='intro-toppad' />
              <Container className='intro-text-1'>
                記下香港同人活動一頁<br />的大型合本計劃
              </Container>
              <Container className='intro-text-2-pad' />
              <Container className='intro-text-2 da-wh'>
                收錄近60位<br />
                香港著名同人繪師的最新作品，<br />
                空前的豪華陣容合集！
              </Container>
              <Container className='intro-text-3-pad' />
              <Container className='intro-text-3'>
                ──這就是，我們的同人道。
              </Container>
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
  )
}

function Footer() {
  return (
    <div className='footer'>
      <Nav className="bottom-nav">
      <Container fluid>
        <Row>
          <Col className="footer-text">
            聯絡我們
          </Col>
        </Row>
        <Row className="logo-row">
          <Col xs={{span:6, offset:3}} md={{span:4, offset:4}}>
            <Row className="logo-row-col">
              <Col xs={3}>
                <Nav.Link href='https://twitter.com/HKDJAntho'>
                  <Container className="twitter-logo logo-scale" />  
                </Nav.Link>
              </Col>
              <Col xs={3}>
                <Nav.Link href='https://www.facebook.com/HKDJAntho'>
                  <Container className="fb-logo logo-scale" />  
                </Nav.Link>
              </Col>
              <Col xs={3}>
                <Nav.Link href='https://www.instagram.com/hkdjantho/'>
                  <Container className="insta-logo logo-scale" />  
                </Nav.Link>
              </Col>
              <Col xs={3}>
                <Nav.Link href='mailto:hkdoujinanthology@gmail.com'>
                  <Container className="mail-logo logo-scale-mail" />  
                </Nav.Link>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="footer-text-small">
            © 香港同人繪師選集 Doujin Anthology HK <br /> All rights reserved.
          </Col>
        </Row>
      </Container>
      </Nav>
    </div>
    // <div className='footer'>
    //   <Container>
    //     <Row>
    //       <Col className='footer-text'>聯絡我們</Col>
    //     </Row>
    //     <Row>
    //       <Col xs={{span:6, offset:3}} md={{span:4, offset:4}}>
    //         <Row>
    //           <Col xs={4}>
    //             <a className='twitter-logo' href='https://twitter.com/home'/>
    //           </Col>

    //         </Row>
    //       </Col>
    //     </Row>
    //   </Container>
    // </div>
  )
}

function NewsItem(props) {
  return (
    <>
      <div className='news-title'>
        {props.title}
      </div>
      <div className='news-content' dangerouslySetInnerHTML={props.text} />
    </>
  )
}

const renderScroll = ({ style, ...props}) => {
  const thumbStyle={
    borderRadius: 6,
    backgroundColor: '#f0c934'
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
}

class NewsList extends React.Component {

  createNews(title, text, key) {
    return (
      <NewsItem title={title} key={key} text={text} />
    )
  }

  createNewsList() {
    var all_news = [['2021.11.10', '公佈了<a href="https://www.rainbow-gala.com/26/main.html" class="da-link" target="_blank" rel="noreferrer">Rainbow Gala 26</a>攤位配置，攤位位於 6樓 WHITE 11B「香港同人繪師選集 Doujin Anthology HK」。'], ['2021.11.26', '於<a href="https://www.facebook.com/HKDJAntho" class="da-link" target="_blank" rel="noreferrer">Facebook Page</a>、<a href="https://www.instagram.com/hkdjantho/" class="da-link" target="_blank" rel="noreferrer">Instagram</a>、<a href="https://twitter.com/HKDJAntho" class="da-link" target="_blank" rel="noreferrer">Twitter</a>正式公開企劃。'], ['2021.12.17', '活動網頁啟用！']]
    var news_list = []
    for (var i=all_news.length-1; i>=0; i--){
      news_list.push(this.createNews(all_news[i][0], {__html: all_news[i][1]}, i))
    }
    return (
      <>
        {news_list}
      </>
    )
  }

  render() {
    return (
      <>
        <Scrollbars renderThumbVertical={renderScroll} style={{height: 400}}>
          <Stack gap={2}>
            {this.createNewsList()}
          </Stack>
        </Scrollbars>
      </>
    )
  }
}

function News() {
  return (
    <Container className="news-box">
      <Header text="N e w s" />
      <NewsList />
    </Container>
  )
}

function Twitter() {
  return (
    <Container className="twitter-box">
      <Header text="T w i t t e r" />
      <Timeline dataSource={{sourceType: "profile", screenName: "HKDJAntho"}} options={{height: '400'}} />
    </Container>
  )
}

function Updates() {
  return (
    <Row className='update-box'>
      <Col xs={12} md={7}>
        <News />
      </Col>
      <Col xs={12} md={5}>
        <Twitter />
      </Col>
    </Row>
  )
}

// function Body() {
//   return (
//     <Container fluid className='body-box'>
//       <Cover />
//       <Intro />
//     </Container>
//   )
// }

function Home() {
  return (
    <>
      <Cover />
      <Intro />
      <Updates />
    </>
  )
}

function App() {
  return (
    <>
      <BrowserRouter>
        <Topbar />
        <NavbarPad />
        {/* <Cover />
        <Intro /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="artists" element={<Artists />} />
          <Route path="purchase" element={<Purchase />} />
          <Route path="events" element={<Events />} />
        </Routes>
        <BottomPad />
        <Footer />
      </BrowserRouter>
    </>
  );
}

// const ExampleToast = ({ children }) => {
//   const [show, toggleShow] = useState(true);

//   return (
//     <>
//       {!show && <Button onClick={() => toggleShow(true)}>Show Toast</Button>}
//       <Toast show={show} onClose={() => toggleShow(false)}>
//         <Toast.Header>
//           <strong className="mr-auto">React-Bootstrap</strong>
//         </Toast.Header>
//         <Toast.Body>{children}</Toast.Body>
//       </Toast>
//     </>
//   );
// };

// const App = () => (
//   <Container className="p-3">
//     <Container className="p-5 mb-4 bg-light rounded-3">
//       <h1 className="header">Welcome To React-Bootstrap</h1>
//       <ExampleToast>
//         We now have Toasts
//         <span role="img" aria-label="tada">
//           🎉
//         </span>
//       </ExampleToast>
//     </Container>
//   </Container>
// );

export default App;
